exports.components = {
  "component---src-components-article-tsx": () => import("./../../../src/components/article.tsx" /* webpackChunkName: "component---src-components-article-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-articles-tsx": () => import("./../../../src/pages/articles.tsx" /* webpackChunkName: "component---src-pages-articles-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-not-tech-tsx": () => import("./../../../src/pages/not-tech.tsx" /* webpackChunkName: "component---src-pages-not-tech-tsx" */),
  "component---src-pages-portfolio-tsx": () => import("./../../../src/pages/portfolio.tsx" /* webpackChunkName: "component---src-pages-portfolio-tsx" */),
  "component---src-pages-reading-tsx": () => import("./../../../src/pages/reading.tsx" /* webpackChunkName: "component---src-pages-reading-tsx" */),
  "component---src-pages-side-projects-helping-small-business-tsx": () => import("./../../../src/pages/side-projects/helping-small-business.tsx" /* webpackChunkName: "component---src-pages-side-projects-helping-small-business-tsx" */),
  "component---src-pages-side-projects-tsx": () => import("./../../../src/pages/side-projects.tsx" /* webpackChunkName: "component---src-pages-side-projects-tsx" */),
  "component---src-pages-side-projects-whats-on-tsx": () => import("./../../../src/pages/side-projects/whats-on.tsx" /* webpackChunkName: "component---src-pages-side-projects-whats-on-tsx" */),
  "component---src-pages-talks-tsx": () => import("./../../../src/pages/talks.tsx" /* webpackChunkName: "component---src-pages-talks-tsx" */),
  "component---src-pages-twitter-archive-tsx": () => import("./../../../src/pages/twitter-archive.tsx" /* webpackChunkName: "component---src-pages-twitter-archive-tsx" */)
}

